function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"adjust-saldo-approval"},[(_vm.controller.isLoading)?_c('LoadingOverlay'):_vm._e(),_c('h1',{staticClass:"font-bold text-base text-gray-4 mb-6"},[_vm._v(" Daftar Permintaan Adjust Saldo ")]),_c('div',{staticClass:"flex gap-3 mb-6"},[_c('div',{staticClass:"w-full"},[_c('label',{staticClass:"font-semibold text-sm text-bgMatterhorn"},[_vm._v("Cari")]),_c('TextInput',{attrs:{"placeholder":"Cari nama file, email pengirim atau penerima","type":"search"},on:{"input":_vm.onFilter},model:{value:(_vm.parameters.keyword),callback:function ($$v) {_vm.$set(_vm.parameters, "keyword", $$v)},expression:"parameters.keyword"}})],1),_c('div',{staticClass:"w-6/12"},[_c('label',{staticClass:"font-semibold text-sm text-bgMatterhorn"},[_vm._v("Tanggal")]),_c('DateTimePicker',{attrs:{"id":"start-date","valueFormat":"date","withIcon":"","noButtonNow":"","label":"Pilih Tanggal","formatted":"DD MMM YYYY","autoClose":true},on:{"input":_vm.onFilter},model:{value:(_vm.parameters.date),callback:function ($$v) {_vm.$set(_vm.parameters, "date", $$v)},expression:"parameters.date"}})],1),_c('div',{staticClass:"w-4/12"},[_c('label',{staticClass:"font-semibold text-sm text-bgMatterhorn"},[_vm._v("Status")]),_c('Dropdown',{attrs:{"options":_vm.filterOptions},on:{"input":_vm.onFilter},model:{value:(_vm.parameters.status),callback:function ($$v) {_vm.$set(_vm.parameters, "status", $$v)},expression:"parameters.status"}})],1),_c('div',{staticClass:"w-4/12"},[_c('label',{staticClass:"font-semibold text-sm text-bgMatterhorn"},[_vm._v("Urutkan")]),_c('Dropdown',{attrs:{"options":_vm.sortOptions},on:{"input":_vm.onFilter},model:{value:(_vm.parameters.sort),callback:function ($$v) {_vm.$set(_vm.parameters, "sort", $$v)},expression:"parameters.sort"}})],1)]),_c('DataTableV2',{attrs:{"isLoading":false,"headers":_vm.headers,"dataItems":_vm.data},scopedSlots:_vm._u([{key:"0",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var props = rest;
return [_c('span',{staticClass:"font-medium text-sm text-gray-4"},[_vm._v(_vm._s(props.data.value))])]}},{key:"2",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var data = rest;
return [_c('div',{staticClass:"text-sm text-secondaryText"},[(data.data.value.length)?[_c('div',[_vm._v(_vm._s(data.data.value[0]))]),_c('div',[_vm._v(_vm._s(data.data.value[1]))])]:[_vm._v("-")]],2)]}},{key:"4",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var data = rest;
return [_c('div',{staticClass:"text-sm text-secondaryText"},[(data.data.value.length)?[_c('div',[_vm._v(_vm._s(data.data.value[0]))]),_c('div',[_vm._v(_vm._s(data.data.value[1]))])]:[_vm._v("-")]],2)]}},{key:"5",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var props = rest;
return [_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"notes-text"},[_vm._v(_vm._s(props.data.value))]),(props.data.value !== '-')?_c('span',{staticClass:"text-2xs text-bgPrimary cursor-pointer",on:{"click":function () { return _vm.openNotes(props.data.value); }}},[_vm._v("Lihat Detail")]):_vm._e()])]}},{key:"6",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var props = rest;
return [_c('Badge',{staticClass:"w-full w-32-a flex justify-center px-4 py-1 text-center hdpi-laptop:w-3/4",attrs:{"type":_vm.coloringStatus(props.data.value),"customPadding":true}},[_c('span',[_vm._v(_vm._s(props.data.value))])])]}},{key:"7",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var props = rest;
return [_c('div',{staticClass:"flex gap-2 cursor-pointer",on:{"click":function($event){return _vm.navigateToDetail(props.data.value)}}},[_c('EyeIcon'),_c('span',{staticClass:"text-sm text-bgPrimary font-semibold"},[_vm._v("Lihat")])],1)]}}])}),_c('div',{staticClass:"mt-4"},[_c('PaginationNav',{staticClass:"mb-8 mt-2",attrs:{"page":_vm.parameters.page,"perPage":_vm.parameters.per_page,"totalItem":_vm.controller.pagination.totalItem,"enablePerPageOption":true,"perPageOptions":_vm.perPageOptions},on:{"onChangeOption":function($event){return _vm.fetchingApprovalAdjustSaldoListPerPage($event)},"input":function($event){return _vm.fetchingApprovalAdjustSaldoList(_vm.parameters)}},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}})],1),_c('ModalNotes',{attrs:{"visible":_vm.modalNotes.visible,"notes":_vm.modalNotes.notes,"title":"Detail Alasan"},on:{"close":function () { return (_vm.modalNotes.visible = false); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }