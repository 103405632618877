


























































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import Dropdown from '@/app/ui/components/DropdownSelect/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import EyeIcon from '@/app/ui/assets/eye_filled.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import ModalNotes from '../components/Modals/ModalNotes/index.vue'
import { DataObject } from '@/app/ui/components/DataTableV2/type'
import dayjs from 'dayjs'
import dayjsID from 'dayjs/locale/id'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import controller from '@/app/ui/controllers/ApprovalAdjustSaldoController'
import { ApprovalAdjustSaldo } from '@/domain/entities/ApprovalAdjustSaldo'
import { Utils } from '@/app/infrastructures/misc'
import { EnumAdjustSaldoStatus } from '@/app/infrastructures/misc/Constants/adjustSaldoApproval'
import Badge from '@/app/ui/components/Badge/index.vue'

interface IOptions {
  label: string
  value: string | number
}

interface IParams {
  page: number
  per_page: number
  sort: IOptions
  keyword: string
  status: IOptions
  date: string
  time_zone: string
}

@Component({
  components: {
    TextInput,
    DateTimePicker,
    Dropdown,
    DataTableV2,
    EyeIcon,
    PaginationNav,
    ModalNotes,
    LoadingOverlay,
    Badge
  },
})
export default class AdjustSaldoApproval extends Vue {
  controller = controller
  enumAdjustSaldoStatus = EnumAdjustSaldoStatus
  headers = [
    {
      title: 'Nama File',
      customStyle: { minWidth: '220px', maxWidth: '220px' },
    },
    {
      title: 'Dikirim Oleh',
      customStyle: { minWidth: '225px', maxWidth: '225px' },
    },
    {
      title: 'Tanggal Dikirim',
      customStyle: { minWidth: '200px', maxWidth: '200px' },
    },
    {
      title: 'Diterima/Tolak Oleh',
      customStyle: { minWidth: '225px', maxWidth: '225px' },
    },
    {
      title: 'Tanggal Terima/Tolak',
      customStyle: { minWidth: '199px', maxWidth: '199px' },
    },
    {
      title: 'Catatan',
      customStyle: { minWidth: '225px', maxWidth: '225px' },
    },
    {
      title: 'Status',
      customStyle: { minWidth: '180px', maxWidth: '180px' },
    },
    {
      title: 'Aksi',
      customStyle: { minWidth: '141px', maxWidth: '141px' },
    },
  ]

  data: DataObject[][] = []

  filterOptions = [
    { label: 'Semua', value: '',  },
    { label: 'Terima', value: this.enumAdjustSaldoStatus.APPROVE },
    { label: 'Tolak', value: this.enumAdjustSaldoStatus.REJECT },
    { label: 'Menunggu', value: this.enumAdjustSaldoStatus.WAITING_FOR_APPROVAL }
  ]

  sortOptions = [
    { label: 'Terbaru', value: 'DESC' },
    { label: 'Terlama', value: 'ASC' },
  ]

  parameters: IParams = {
    page: 1,
    per_page: 10,
    sort: this.sortOptions[0],
    keyword: '',
    status: this.filterOptions[0],
    date: '',
    time_zone: dayjs()
      .locale(dayjsID)
      .format('Z'),
  }

  perPageOptions: IOptions[] = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  modalNotes = {
    visible: false,
    notes: '-',
  }

  private manageDataApprovalSaldo(data: ApprovalAdjustSaldo[]) {
    this.data = <DataObject[][]>data.map((item) => {
      return [
        {
          value: item.filename || '-',
          customStyle: { minWidth: '220px', maxWidth: '220px' },
        },
        {
          value: item.createdBy || '-',
          customStyle: { minWidth: '225px', maxWidth: '225px' },
        },
        {
          value:
          item.createdAt
            ? [
                Utils.formatDateWithIDLocale(
                  item.createdAt,
                  'dddd DD MMM YYYY,'
                ),
                Utils.formatTimeZone(
                  Utils.formatDateWithIDLocale(
                    item.createdAt,
                    'HH:mm Z'
                  )
                )
              ]
            : [],
          customStyle: { minWidth: '200px', maxWidth: '200px' },
        },
        {
          value: item.updatedBy || '-',
          customStyle: { minWidth: '225px', maxWidth: '225px' },
        },
        {
          value:
          item.updatedAt
            ? [
                Utils.formatDateWithIDLocale(
                  item.updatedAt,
                  'dddd DD MMM YYYY,'
                ),
                Utils.formatTimeZone(
                  Utils.formatDateWithIDLocale(
                    item.updatedAt,
                    'HH:mm Z'
                  )
                )
              ]
            : [],
          customStyle: { minWidth: '199px', maxWidth: '199px' },
        },
        {
          value: item.reason || '-',
          customStyle: { minWidth: '225px', maxWidth: '225px' },
        },
        {
          value: this.formatedStatus(item.status || ''),
          customStyle: { minWidth: '220px', maxWidth: '220px' },
        },
        {
          value: item.id || '-',
          customStyle: { minWidth: '141px', maxWidth: '141px' },
        },
      ]
    })
  }

  created(): void {
    this.getApprovalAdjustSaldoList(this.parameters)
  }

  private onFilter = Utils.debounce(() => {
    this.getApprovalAdjustSaldoList(this.parameters)
  }, 500)

  private getApprovalAdjustSaldoList(params: IParams) {
    const param: Record<string, string | number> = {
      page: params.page,
      per_page: params.per_page,
      sort: params.sort.value,
      keyword: params.keyword,
      status: params.status.value,
    }

    if(params.date) {
      param.date = params.date
      param.time_zone = encodeURIComponent(params.time_zone)
    }

    controller.getAll(param)
  }

  private formatedStatus(status: string): string {
    let result = '-'
    switch(status) {
      case this.enumAdjustSaldoStatus.APPROVE:
        result = 'Terima'
        break;
      case this.enumAdjustSaldoStatus.REJECT:
        result = 'Tolak'
        break;
      case this.enumAdjustSaldoStatus.WAITING_FOR_APPROVAL:
        result = 'Menunggu'
        break
    }
    return result
  }

  private coloringStatus(status: string): string {
    let result = ''
    switch (status) {
      case 'Terima':
        result = 'success'
        break
      case 'Tolak':
        result = 'error-1'
        break
      case 'Menunggu':
        result = 'warning'
        break
    }
    return result
  }

  private fetchingApprovalAdjustSaldoListPerPage(value: number): void {
    this.parameters.per_page = value
    this.getApprovalAdjustSaldoList(this.parameters)
  }

  private fetchingApprovalAdjustSaldoList() {
    this.getApprovalAdjustSaldoList(this.parameters)
  }

  private openNotes(value: string): void {
    this.modalNotes.visible = true
    this.modalNotes.notes = value
  }

  private navigateToDetail(id: number): void {
    this.$router.push('/payroll/adjust-saldo-approval/detail/' + id)
  }

  @Watch('controller.data')
  onWatchApprovalAdjustSaldo(value: ApprovalAdjustSaldo[]): void {
    this.manageDataApprovalSaldo(value)
  }
}
